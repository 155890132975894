import { EndValuesInterface } from 'services/turnover-service'

export const customersNumber = (customersNumber: string): void => {
  const $customersNumberHolders = document.querySelectorAll<HTMLSpanElement>('[data-customers-number]')
  $customersNumberHolders.forEach(($customersNumberHolder) => {
    // eg. 123456 ->> 123 || 123654 ->> 124
    const displayNumber = Math.round(parseInt(customersNumber, 10) / 1000).toString()
    $customersNumberHolder.innerHTML = displayNumber
  })
}

export const wtTurnover = (turnover: EndValuesInterface): void => {
  const $turnoverNumberHolder = document.querySelector<HTMLSpanElement>('[data-wt-turnover]')
  const $turnoverTextHolder = document.querySelector<HTMLSpanElement>('[data-sum-name]')
  if ($turnoverNumberHolder) $turnoverNumberHolder.textContent = turnover.changedValue
  if ($turnoverTextHolder) $turnoverTextHolder.textContent = turnover.sumName
}
