const handleExchangeButton = (): void => {
  const signUpButtons = document.querySelectorAll<HTMLButtonElement>('[data-sign-button]')
  const signUpButtonsWrap = document.querySelectorAll<HTMLDivElement>('[data-buttons-wrap]')

  signUpButtons.forEach((button) => {
    button.addEventListener('click', (e): void => {
      const target = e?.target as HTMLElement
      target.nextElementSibling?.toggleAttribute('hidden')
    })
  })

  window.addEventListener('click', (e): void => {
    const target = e?.target as HTMLElement
    signUpButtonsWrap.forEach((signUpbuttoWrap): void => {
      if (!target.hasAttribute('data-sign-button') && !target.hasAttribute('data-buttons-wrap')) {
        signUpbuttoWrap.hidden = true
      }
    })
  })
}

export const inithandleExchangeButton = (): void => {
  handleExchangeButton()
}
