import { isEmailValidator } from '@currency-one/validators'
import { pushDataLayer } from 'utils/data-layer-push'
import { signUpForNewsletter } from 'services/newsletter-service'
import { t9n } from 'src/assets/scripts/t9n/t9n'
import { NewsletterPayload } from 'src/assets/scripts/models/endpoints-payload'

const removeErrorMessages = (): void => {
  const $errors = document.querySelectorAll<HTMLElement>('[data-error-field]')
  $errors.forEach(($error: HTMLElement) => {
    $error.hidden = true
  })
}

const showError = (errorType: string, errorPlace: string): void => {
  const $errorElement = document.querySelector<HTMLElement>(`[data-error-field="${errorPlace}"]`)

  if ($errorElement) {
    $errorElement.hidden = false
    $errorElement.textContent = t9n(`formValidation.${errorType}`)
  }
}

const doPostRequest = (): void => {
  const $email = document.querySelector<HTMLInputElement>('[data-newsletter-email]').value

  if ($email.length <= 0) {
    showError('emptyField', 'email')
    return
  }

  if (!isEmailValidator($email)) {
    showError('emailNotValid', 'email')
    return
  }

  const $rodo = document.querySelector<HTMLInputElement>('[data-newsletter-rodo]').checked
  if (!$rodo) {
    showError('requiredField', 'rodo')
    return
  }

  const payload: NewsletterPayload = {
    email: $email,
  }

  signUpForNewsletter(payload)
    .then((response: any) => {
      if (response.message === 'OK') {
        const $subModal = document.querySelector<HTMLElement>('[js-selector="newsletter-popup"]')
        $subModal.hidden = true

        const $succModal = document.querySelector<HTMLElement>('[js-selector="newsletter-popup-success"]')
        $succModal.hidden = false

        pushDataLayer('form_submitted', { form_name: 'Zapis do Newslettera' })
      }
    })
    .catch((err) => {
      if (err.status === 422) {
        showError('emailReserved', 'email')
      } else {
        showError('serverUnknownError', 'server')
      }
    })
}

const bindModalActions = (): void => {
  const $closeModal = document.querySelector<HTMLElement>('[action-hide-newsletter-modal]')
  if ($closeModal) {
    $closeModal.addEventListener(
      'click',
      () => {
        removeErrorMessages()
        const $modal = document.querySelector<HTMLElement>('[js-selector="newsletter-popup"]')
        $modal.hidden = true

        pushDataLayer('click_button', {
          button_type: 'Button',
          button_text: 'Zamknięcie popupu newslettera',
        })
      },
      false,
    )
  }

  const $closeSuccess = document.querySelectorAll<HTMLElement>('[action-hide-newsletter-success-modal]')
  $closeSuccess.forEach(($singleCloseButton) => {
    $singleCloseButton.addEventListener(
      'click',
      () => {
        const $modal = document.querySelector<HTMLElement>('[js-selector="newsletter-popup-success"]')
        $modal.hidden = true
      },
      false,
    )
  })

  const $openModal = document.querySelector<HTMLElement>('[action-show-newsletter-modal]')
  if ($openModal) {
    $openModal.addEventListener(
      'click',
      () => {
        const modal = document.querySelector<HTMLElement>('[js-selector="newsletter-popup"]')
        modal.hidden = false
      },
      false,
    )
  }
}

const bindFormActions = (): void => {
  const $submitButton = document.querySelector('[data-submit-newsletter]')

  if ($submitButton) {
    $submitButton.addEventListener(
      'click',
      () => {
        removeErrorMessages()
        doPostRequest()
      },
      false,
    )
  }
}

const initNewsletterPopup = (): void => {
  bindModalActions()
  bindFormActions()
}

export default initNewsletterPopup
