import { formatNumber } from 'utils/format-number'
import { MarketBrief } from 'models/market'

const getCurrencyRatesByIndex = (payload: MarketBrief[], currencyIndex: string) =>
  payload.find((el) => el.pair === currencyIndex)

const isCurrencyDataInPayload = (payload: MarketBrief[], currencyIndex: string): boolean =>
  !!getCurrencyRatesByIndex(payload, currencyIndex) || false

const updateRateTrend = (trendDirection: string, $trendElement: HTMLSpanElement): void => {
  $trendElement.classList.remove('is-up', 'is-down')

  if (trendDirection === 'up' || trendDirection === 'down') $trendElement.classList.add(`is-${trendDirection}`)
}

const updateRate = (direction: string, rate: MarketBrief, $singleRateElement: HTMLDivElement): void => {
  const actualRate = direction === 'bid' ? rate.bestOffers.bid_now : rate.bestOffers.ask_now
  const trendDirection = direction === 'bid' ? rate.bestOffers.bid_trend : rate.bestOffers.ask_trend
  const $rateElement = $singleRateElement.querySelector<HTMLSpanElement>('[data-rate-value]')
  const $trendElement = $singleRateElement.querySelector<HTMLSpanElement>('[data-rate-trend]')

  $rateElement.innerHTML = `${formatNumber(actualRate || 0, 4)} PLN`

  updateRateTrend(trendDirection, $trendElement)
}

const updateWidgetUI = ($element: HTMLDivElement, currencyIndex: string, payload): void => {
  if (!isCurrencyDataInPayload(payload, currencyIndex)) return
  const $directionFragments = $element.querySelectorAll<HTMLDivElement>('[data-rate-direction]')
  $directionFragments.forEach(($singleRateElement) => {
    updateRate(
      $singleRateElement.dataset.rateDirection,
      getCurrencyRatesByIndex(payload, currencyIndex),
      $singleRateElement,
    )
  })
}

const resetTimer = (): void => {
  const $timer = document.querySelector<HTMLSpanElement>('[data-widget-timer]')
  if ($timer) $timer.innerHTML = '30'
}

export function handleFeaturedRatesUpdate(payload: MarketBrief[]): void {
  const $rateElements = document.querySelectorAll<HTMLDivElement>('[data-currency-index]')
  $rateElements.forEach(($singleRateElement) => {
    const currencyIndex = $singleRateElement.dataset.currencyIndex
    updateWidgetUI($singleRateElement, currencyIndex, payload)
  })
  resetTimer()
}

export function handleFeaturedTimer(): void {
  const $timer = document.querySelector<HTMLSpanElement>('[data-widget-timer]')
  if ($timer) {
    const actualTime = parseInt($timer.innerHTML, 10)
    $timer.innerHTML = String(actualTime > 0 ? actualTime - 1 : 0)
  }
}
