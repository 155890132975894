import { handleFeaturedRatesUpdate, handleFeaturedTimer } from './handleFeaturedRatesUpdate'
import { wtTurnover } from './handleCounters'
import { EndValuesInterface } from 'services/turnover-service'
import { MarketBrief } from 'models/market'

declare const USER_BASE_URL_WT: string
declare const CMS_BASE_URL_WT: string

/**
 * Inform web worker to init fetching marketBrief in intervals.
 *
 * @param {*} worker
 */
const initMarketBriefInterval = (worker: Worker): void => {
  worker.postMessage(['initMarketBrief', { interval: 30000, baseUrl: USER_BASE_URL_WT }])
  worker.postMessage(['initTickTock'])
}

const initTurnover = (worker: Worker): void => {
  worker.postMessage(['initTurnover', { baseUrl: USER_BASE_URL_WT }])
}

const workerHandler = (actionType: string, payload: any): void => {
  const workerJobs = {
    tickTock: (): void => {
      handleFeaturedTimer()
    },
    marketBriefUpdate: (payload: MarketBrief[]): void => {
      handleFeaturedRatesUpdate(payload)
    },
    wtTurnoverUpdate: (payload: EndValuesInterface): void => {
      wtTurnover(payload)
    },
    missingAction: (): void => {
      console.error('[API] Iddle worker run')
    },
  }
  workerJobs[actionType] ? workerJobs[actionType](payload) : workerJobs['missingAction']()
}

export function initWebWorker(): void {
  if (window.Worker) {
    const wtWorker = new Worker(
      // TODO: Fix issue with this long absolute URL.
      new URL(`${CMS_BASE_URL_WT}/wp-content/themes/wt-theme/assets/scripts/global/webWorker.js`),
    )

    /* PUT HERE ALL WORKER JOBS */
    initMarketBriefInterval(wtWorker)
    initTurnover(wtWorker)

    /* PUT HERE ALL WORKER MESSAGE HANDLERS */
    wtWorker.onmessage = (event: MessageEvent): void => {
      const action = event.data.action || 'missingAction'
      const payload = event.data.payload || []
      workerHandler(action, payload)
    }
  } else {
    // TODO: Should we fallback if no webworker available?
    console.error('[Compatibility] WebWorkers not supported.')
  }
}
