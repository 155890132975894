import { NewsletterPayload } from 'models/endpoints-payload'
import { promiseWrapper } from 'utils/promise'

declare const USER_BASE_URL_WT: string

export const signUpForNewsletter = (payload: NewsletterPayload): Promise<void> => {
  payload['list'] = '24um3b3gnc'
  const FRESHMAIL_SUBSCRIBE_API =
    USER_BASE_URL_WT === 'https://user.walutomat.pl'
      ? 'https://lrr0f4g0l2.execute-api.eu-central-1.amazonaws.com/default/generateAPIHashForFreshmail'
      : ''
  return promiseWrapper<void, object>(FRESHMAIL_SUBSCRIBE_API, 'POST', payload)
}
